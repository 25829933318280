import {
    Avatar,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import Alert from '@material-ui/lab/Alert';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import React, { useEffect, useRef, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import EditInLanguageSelector from "../components/EditInLanguageSelector";
import FileUpload from "../components/FileUpload";
import ProgressDialog from "../components/ProgressDialog";
import { useAuth } from '../components/ProvideAuth';
import { Language, Path, Role, SocialMediaName, SponsorType } from '../data/Constants';
import SponsorService from "../services/SponsorService";
import UserService from "../services/UserService";
import { styles } from "../styles/FormStyles";
import { requiredTranslation, requiredValidation, urlValidation } from "../utils/Validators";

/**
 * Displays an event edit view
 * @author dame.gjorgjievski
 */
export default function SponsorEditView() {

    const auth = useAuth()
    const { t } = useTranslation()
    const classes = styles()
    const history = useHistory()
    const [users, setUsers] = useState([])
    const [state, setState] = useState({
        name: {
            [Language.DEFAULT]: "New Sponsor"
        },
        description: {
            [Language.DEFAULT]: ""
        },
        type: SponsorType.SILVER,
        url: "",
        image: null,
        media: null,
        files: [],
        users: [],
        features: {
            enablePage: true,
            showInLobby: true
        },
        socialMedia: {}
    })
    let [errorz, setErrorz] = useState({
        name: "",
        type: "",
        url: ""
    })
    const [language, setLanguage] = useState(Language.current())
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    let timeout = useRef(0)
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(ContentState.createFromText(''))
    );

    const onUploadComplete = (field) => (response) => {
        switch (field) {
            case "media":
            case "image":
            case "video":
                setState({ ...state, [field]: response.data })
                break;
            case "files":
                let files = state[field]
                if (files == null) return
                files.push(response.data);
                setState({ ...state, [field]: files })
                break;
            default:
                break;
        }

    }

    const onRemoveFile = (field) => (file) => {
        switch (field) {
            case "media":
            case "image":
            case "video":
                setState({ ...state, [field]: null })
                break;
            case "files":
                let files = state[field];
                if (files == null) return
                let idx = files.indexOf(file)
                if (idx > -1) files.splice(idx, 1)
                setState({ ...state, [field]: files })
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        setLoading(parseInt(id) > 0)
        SponsorService.load(id).then((result) => {
            setState(result.data)
            setLoading(false)

            let desc;
            try {
                desc = convertFromRaw(JSON.parse(result.data.description[language]))
            } catch (e) {
                desc = ContentState.createFromText('')
            }

            setEditorState(EditorState.createWithContent(desc));
        })
        return () => clearInterval(timeout.current)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        errorz = { ...errorz, name: requiredTranslation("Sponsor name", state.name) }
        errorz = { ...errorz, type: requiredValidation("Sponsor Type", state.type) }
        errorz = { ...errorz, url: urlValidation(state.url) }

        setErrorz(errorz)
        clearErrors()
        let props = ["name", "type", "url"]

        for (let i in props) {
            if (errorz[props[i]] && errorz[props[i]].length > 0) return
        }

        const description = { ...state.description, [language]: JSON.stringify(convertToRaw(editorState.getCurrentContent())) }
        setLoading(true)
        const sponsorData = state;
        sponsorData.description = description

        SponsorService.save(sponsorData).then((result) => {
            setLoading(false)
            auth.hasRole(Role.SPONSOR) ? history.push(Path.SPONSORS) : history.push(Path.ADMIN_SPONSORS)
        }).catch((error) => {
            setLoading(false)
            console.log("submit sponsor error", error.response)
            setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearErrors()
        })
    }

    const handleChange = (field, e) => {
        switch (field) {
            case "name":
                const name = { ...state.name, [language]: e.target.value }
                setState({ ...state, name })
                setErrorz({ ...errorz, name: requiredTranslation("Name", name) })
                break;
            case "type":
                setState({ ...state, type: e.target.value })
                setErrorz({ ...errorz, type: requiredValidation("Type", e.target.value) })
                break;
            case "url":
                setState({ ...state, url: e.target.value })
                setErrorz({ ...errorz, url: urlValidation(e.target.value) })
                break;
            case "enablePage":
            case "showInLobby":
                setState({ ...state, features: { ...state.features, [field]: e.target.checked } })
                break;
            case 'socialMedia':
                const newSocialMedia = state.socialMedia
                newSocialMedia[e.target.name] = e.target.value
                setState({ ...state, socialMedia: newSocialMedia })
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        timeout.current = setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <ProgressDialog open={loading} />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddBoxOutlined />
                </Avatar>
                <Grid spacing={2} container direction="row" justify="center" alignItems="center">
                    <Grid item><Typography component="h1" variant="h5">{t('admin.sponsor.title-edit')}</Typography></Grid>
                    <Grid item><EditInLanguageSelector language={language} onChange={e => {
                        setLanguage(e.target.value)
                    }} /></Grid>
                </Grid>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    required
                                    id="name"
                                    label={t('admin.default.fields.name') + " (" + language + ")"}
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    value={state.name[language] || ""}
                                    onChange={(e) => handleChange('name', e)}
                                    error={errorz.name !== ''}
                                    helperText={errorz.name}
                                />
                            </FormControl>
                            <FormControl fullWidth error={errorz.type !== ""} className={classes.formControl}>
                                <InputLabel id="access-select-label" className={classes.formLabel}>Type *</InputLabel>
                                <Select
                                    labelId="access-select-label"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="type"
                                    label={t('admin.default.fields.type')}
                                    name="type"
                                    autoComplete="type"
                                    value={state.type}
                                    onChange={(e) => handleChange('type', e)}
                                    error={errorz.type !== ""}
                                >
                                    <MenuItem value=""><em>{t('data.sponsor-type.none')}</em></MenuItem>
                                    <MenuItem value={SponsorType.FOUNDING_PARTNER}>{t('data.sponsor-type.founding_partner')}</MenuItem>
                                    <MenuItem value={SponsorType.PARTNER}>{t('data.sponsor-type.partner')}</MenuItem>
                                </Select>
                                <FormHelperText className={classes.helperText}>{errorz.access}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Autocomplete
                                    value={state.users}
                                    multiple
                                    autoComplete
                                    options={users}
                                    filterOptions={(x) => x}
                                    onInputChange={(event, value) => {
                                        UserService.query(value).then(response => {
                                            setUsers(response.data)
                                        })
                                    }}
                                    onChange={(e, value) => {
                                        setState({ ...state, users: [...value] })
                                    }}
                                    getOptionLabel={(option) => option.name + " " + option.surname}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('admin.default.fields.users')} variant="outlined"
                                            fullWidth />
                                    )}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 4 }}
                                                checked={selected}
                                            />
                                            {option.name + " " + option.surname + " (" + option.email + ")"}
                                        </React.Fragment>
                                    )}
                                    getOptionSelected={(option, value) => {
                                        return state.users.filter(u => u.id === option.id)[0] != null
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl} component="fieldset">
                                <FormLabel component="legend"
                                    className={classes.formLabel}>{t('admin.default.fields.features')}</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox name="enablePage" checked={state.features.enablePage}
                                            onChange={(e) => handleChange("enablePage", e)} />}
                                        label={t('admin.sponsor.fields.feature-page')} />
                                    <FormControlLabel
                                        control={<Checkbox name="showInLobby" checked={state.features.showInLobby}
                                            onChange={(e) => handleChange("showInLobby", e)} />}
                                        label={t('admin.sponsor.fields.feature-show-lobby')} />
                                </FormGroup>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FormLabel component="legend"
                                    className={classes.formLabel}>{t("admin.default.fields.description")}</FormLabel>
                                <Editor
                                    toolbarClassName={classes.descriptionToolbar}
                                    wrapperClassName={classes.descriptionWrapper}
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    id="url"
                                    label={t('admin.default.fields.url')}
                                    name="url"
                                    autoComplete="url"
                                    value={state.url}
                                    onChange={(e) => handleChange('url', e)}
                                    error={errorz.url !== ""}
                                    helperText={errorz.url}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="linkedIn"
                                    label={t('client.user.labels.linkedIn')}
                                    name={SocialMediaName.LINKEDIN}
                                    autoComplete={SocialMediaName.LINKEDIN}
                                    value={state.socialMedia[SocialMediaName.LINKEDIN] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="facebook"
                                    label={t('client.user.labels.facebook')}
                                    name={SocialMediaName.FACEBOOK}
                                    autoComplete={SocialMediaName.FACEBOOK}
                                    value={state.socialMedia[SocialMediaName.FACEBOOK] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="instagram"
                                    label={t('client.user.labels.instagram')}
                                    name={SocialMediaName.INSTAGRAM}
                                    autoComplete={SocialMediaName.INSTAGRAM}
                                    value={state.socialMedia[SocialMediaName.INSTAGRAM] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="twitter"
                                    label={t('client.user.labels.twitter')}
                                    name={SocialMediaName.TWITTER}
                                    autoComplete={SocialMediaName.TWITTER}
                                    value={state.socialMedia[SocialMediaName.TWITTER] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FileUpload onComplete={onUploadComplete('image')}
                                    files={state.image ? [state.image] : []}
                                    update label={t('admin.default.fields.logo')}
                                    accept={".jpg, .jpeg, .gif, .png"} onRemove={onRemoveFile('image')} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FileUpload onComplete={onUploadComplete('media')}
                                    files={state.media ? [state.media] : []}
                                    update label={t('admin.default.fields.media')}
                                    accept={".jpg, .jpeg, .gif, .png"} onRemove={onRemoveFile('media')} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FileUpload onComplete={onUploadComplete('video')}
                                    files={state.video ? [state.video] : []}
                                    update label={t('admin.sponsor.fields.video')}
                                    accept={".mov, .avi, .mp4"} onRemove={onRemoveFile('video')} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FileUpload onComplete={onUploadComplete("files")} files={state.files}
                                    label={t('admin.default.fields.files')}
                                    accept={".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odx, .txt"}
                                    onRemove={onRemoveFile("files")} />
                            </FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ width: '50%' }}
                                className={classes.submit}
                                onClick={e => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>{t('admin.default.actions.cancel')}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ width: '50%' }}
                                className={classes.submit}
                                onClick={submit}>{t('admin.default.actions.submit')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{ height: '30px' }} />
            <div>
                {errors.length > 0 &&
                    <Alert severity="error">
                        {errors.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                        })}
                    </Alert>
                }
            </div>
        </Container>
    );
}
