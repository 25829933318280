/**
 * Constant enumeration sets
 * @author dame.gjorgjievski
 */

export const SessionStatus = {
    UPCOMING: "UPCOMING",
    LIVE: "LIVE",
    PASSED: "PASSED"
}

export const Role = {
    VIEWER: "VIEWER",
    SPONSOR: "SPONSOR",
    SPEAKER: "SPEAKER",
    ADMIN: "ADMIN",
    SUPER: "SUPER",
    all: function () {
        return [Role.VIEWER, Role.SPONSOR, Role.SPEAKER, Role.ADMIN, Role.SUPER]
    }
}

export const TwoFactorType = {
    EMAIL: "email",
    all: function () {
        return [TwoFactorType.EMAIL]
    }
}

export const EventType = {
    MESSAGE: 'app-message',
    USER_JOIN: "participant-joined",
    USER_UPDATE: "participant-updated",
    USER_LEAVE: "participant-left",
    JOINED_MEETING: "joined-meeting",
}

export const MessageType = {
    TOPIC: "TOPIC",
    SUPPORT: "SUPPORT",
    CHAT: "CHAT",
    NOTIFICATION: "NOTIFICATION"
}

export const SessionType = {
    LIVE: "LIVE",
    BREAKOUT: "BREAKOUT",
    NETWORKING: "NETWORKING",
    EXTERNAL: "EXTERNAL"
}

export const UserAction = {
    CALL: "CALL",
    MAIL: "MAIL",
    MESSAGE: "MESSAGE",
    LINK: "LINK",
    STAR: "STAR"
}

export const SponsorType = {
    PARTNER: "PARTNER",
    FOUNDING_PARTNER: "FOUNDING_PARTNER",
    ALL: ["PARTNER", "FOUNDING_PARTNER"]
}

export const Access = {
    PRIVATE: "PRIVATE",
    PUBLIC: "PUBLIC"
}

export const Visibility = {
    HIDDEN: "HIDDEN",
    VISIBLE: "VISIBLE",
    ANONYMOUS: "ANONYMOUS"
}

export const Path = {
    ROOT: "/",
    EVENTS: "/events",
    LOGIN: "/login",
    REGISTER: "/register",
    PROFILE: "/profile",
    RESET: "/password/reset",
    ADMIN_EVENTS: "/admin/events",
    ADMIN_SESSIONS: "/admin/sessions",
    ADMIN_GROUPS: "/admin/groups",
    ADMIN_SPONSORS: "/admin/sponsors",
    ADMIN_USERS: "/admin/users",
    ADMIN_POLLS: "/admin/polls",
    ADMIN_PAGES: "/admin/pages",
    ADMIN_SPONSOR_EDIT: "/admin/sponsor/edit/:id",
    ADMIN_USER_EDIT: "/admin/user/edit/:id",
    ADMIN_SESSION_EDIT: "/admin/session/edit/:id",
    ADMIN_GROUP_EDIT: "/admin/group/edit/:id",
    ADMIN_EVENT_EDIT: "/admin/event/edit/:id",
    ADMIN_POLL_EDIT: "/admin/poll/edit/:id",
    ADMIN_PAGE_EDIT: "/admin/page/edit/:id",
    EVENT_INTRO: "/event/:eventNameOrId/intro",
    SESSION: "/event/:id/session/:sid",
    EVENT_ATTENDEES: "/event/:id/attendees",
    EVENT_SESSIONS: "/event/:id/sessions",
    EVENT_LOBBY: "/event/:id/lobby",
    EVENT_REPLAY: "/event/:id/replay",
    EVENT_SPONSORS: "/event/:id/sponsors",
    EVENT_SPONSOR: "/event/:id/sponsor/:sid",
    EVENT_ROUND_TABLES: "/event/:id/networking",
    EVENT_SESSION: "/event/:id/session/:sid",
    EVENT_PAGE: "/event/:id/page/:pid",
    SPONSORS: "/sponsors",
    SPONSOR_EDIT: "/sponsor/edit/:id",
}

export const Language = {
    EN: "en",
    DE: "de",
    FR: "fr",
    DEFAULT: "en",
    KEY: "lng",
    current: function () {
        return localStorage.getItem(Language.KEY) || Language.DEFAULT
    }
}

export const Languages = [
    {code: Language.EN, name: 'English'},
    {code: Language.DE, name: 'Deutsch'},
    {code: Language.FR, name: 'Français'}
]

export const EventMail = {
    INVITATION: "event_invitation",
    REGISTRATION: "user_registration"
}

export const SocialMediaName = {
    LINKEDIN: 'linkedin',
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
    WEBSITE: 'website'
}